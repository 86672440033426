<template lang="html">
  <div class="personal">
    <div class="box" v-if="detail">
      <div class="list headPortrait">
        <div class="left">头像</div>
        <div class="right">
          <img :src="detail.merchants_logo" v-avatar alt="" />
        </div>
      </div>
      <div class="list">
        <div class="left">手机号</div>
        <div class="right">
          <span>{{ detail.merchants_contact }}</span>
        </div>
      </div>
      <div class="list">
        <div class="left">身份证号</div>
        <div class="right">
          <span>{{ detail.merchants_card_id }}</span>
        </div>
      </div>
      <div class="list" @click="$router.push({ path: '/personal/place' })">
        <div class="left">户籍所在地</div>
        <div class="right">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="list" @click="$router.push({ path: '/personal/address' })">
        <div class="left">常驻地</div>
        <div class="right">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'personal',
  data() {
    return {
      detail: null
    };
  },
  created() {
    this.getMerchantsInfo();
  },
  methods: {
    getMerchantsInfo() {
      this.$require.HttpPost('/h5/merchants/getMerchants').then((res) => {
        this.detail = res.data;
      });
    },
    //头像上传 /h5/boss/addAndChangeLogo 接口废弃
    afterRead(files) {
      if (files && files.file) {
        let formData = new FormData();
        formData.append('file', files.file);
        this.$require.HttpPost('/system/upload/upImg', formData).then((res) => {
          this.detail.merchants_logo = process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl;
          this.$require
            .HttpPost('/h5/boss/addAndChangeLogo', {
              merchants_logo: process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl
            })
            .then(() => {
              this.$toast('修改成功');
            });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.personal {
  padding: 30px 24px 0 24px;
  .box {
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 30px;
    .list {
      height: 130px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      &.headPortrait {
        height: 150px;
      }
      .left {
        font-size: 28px;
        color: #000000;
      }
      .right {
        font-size: 28px;
        color: #333333;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
        .van-icon {
          color: #b9b9b9;
        }
      }
    }
  }
}
</style>
